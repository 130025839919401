<template>
  <div class="question-question">
    <div class="title">
      {{title }}
      <span class="checks" v-if="is_multiple==1">多选</span>
    </div>
    <div class="options">
      <div
        v-for="item in options"
        v-bind:key="item.value"
        :class="classNames(item.value)"
        @click="onChange(item.value)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
<script>
import { Options } from "../../utils/options";
export default {
  props: ["title", "options", "value", "is_multiple", "exclusiveOption","num"],
  methods: {
    classNames: function(value) {
      if (
        (this.is_multiple==1 && this.value && this.value.indexOf(value) > -1) ||
        value === this.value
      ) {
        return "item active";
      }
      return "item";
    },
    onChange: function(v) {
      if (this.is_multiple==1) {
        // 多选
        const o = new Options(this.value);
        if (this.exclusiveOption === v) {
          // 选择排他性选项
          o.Clean().Add(v);
        } else {
          if (o.Has(this.exclusiveOption)) {
            o.Remove(this.exclusiveOption);
          }
          o.Toggle(v);
        }
        this.$emit("change", o.Data());
      } else {
        this.$emit("change", v);
      }
    },
  },
};
</script>
