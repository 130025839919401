<template>
<div :class="{white:white,black:!white}">
  <div class="gray-bg3 scroll bg-color">
    <div class="fixed-box">
      <!-- <van-nav-bar
        title="问诊"
        left-text=""
        @click-left="onClickLeft"
        fixed
      /> -->
      <div class="question-title">
        <p>
          1.已为您显示您上次评估的问诊选项,您可根据
          当前的身体状态，调整选项后提交。
        </p>
        <p>2. 如无改变，可直接点击“确定提交”。</p>
      </div>
      <div class="test-qu-box">
        <!-- 性别 -->
        <div class="question-list flex-row">
          <p>1.性别</p>
          <div>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="1" checked-color="#07B49A">男</van-radio>
              <van-radio name="2" checked-color="#07B49A">女</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 出生日期 -->
        <div class="question-list flex-row" @click="show = true">
          <p>2.出生日期</p>
          <div>{{ currentDate }} ></div>
        </div>
        <!-- 身高 -->
        <div class="question-list flex-row">
          <p>3.身高</p>
          <div class="question-option">
            <input
              type="tel"
              maxlength="3"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.trim="height"
            />
            <span>cm</span>
          </div>
        </div>
        <!-- 体重 -->
        <div class="question-list flex-row">
          <p>4.体重</p>
          <div class="question-option">
            <input
              type="text"
              maxlength="3"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model.trim="weight"
            />
            <span>&nbsp;kg</span>
          </div>
        </div>
      </div>

      <!-- <div class="main-container"> -->
      <div
        class="question-panel"
        v-for="(item, index) in questions"
        :key="item.id"
      >
        <QuestionSelect
          :title="index + 5 + '.' + item.title"
          :options="
            item.content.map((key, index) => ({
              value: labels[index],
              title: labels[index] + '.' + key.label,
            }))
          "
          :is_multiple="item.is_multiple"
          :exclusiveOption="labels[item.exp_key]"
          :value="values[item.id] || ''"
          :onChange="
            (v) => {
              onChange(item.id, v);
            }
          "
        />
      </div>
      <div class="test-confirm">
        <div class="test-qu-btn" @click="onSubmit">确认提交</div>
      </div>
      <!-- <div class="question-operator">
          <Button @click="onSubmit">确认提交</Button>
        </div> -->
      <div class="mask" v-if="loading">
        <div class="loading">
          <van-loading size="50px" color="#00c5cd" />
        </div>
      </div>
    </div>
    <van-popup
      v-model:show="show"
      position="bottom"
      :round="show"
      style="width: 100%"
    >
      <van-datetime-picker
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @cancel="show = false"
        @confirm="confirmPicker"
        item-height="50"
        visible-item-count="5"
      />
    </van-popup>
  </div>
  <div class="hu-ask">
    <div class="ask-box flex">
      <div class="ask-title flex-1">
          <div v-if="page<2" >
            <div class="flex">
              <!-- 性别 -->
              <div class="ask-sex">
                <div class="sex-title">1.您的性别是</div>
                <div class="til-top flex flex-y">
                    <div class="sex-til" v-bind:class="{ active: sex==1 }" @click="()=>sexFun(1)">男</div>
                    <div class="sex-til" v-bind:class="{ active: sex==2 }" @click="()=>sexFun(2)">女</div>
                </div>
              </div>
              <div class="ask-date">
                <div class="sex-title">2.出生日期</div>
                <div class="til-top flex">
                    <input type="tel" 
                           maxlength="4"
                           oninput="value=value.replace(/[^\d]/g,'')"
                           v-model.trim="year"
                    />
                    <span>年</span>
                    <input type="tel" 
                           maxlength="2"
                           oninput="value=value.replace(/[^\d]/g,'')"
                           v-model.trim="month"
                    />
                    <span>月</span>
                    <input type="tel" 
                           maxlength="2"
                           oninput="value=value.replace(/[^\d]/g,'')"
                           v-model.trim="day"
                    />
                    <span>日</span>
                </div>
              </div>
            </div>
            <div class="flex ask-top">
              <div class="ask-sex">
                <div class="sex-title">3.身高</div>
                <div class="til-top flex">
                    <input type="text"
                           maxlength="3"
                           oninput="value=value.replace(/[^\d]/g,'')"
                           v-model.trim="height"
                    />
                    <span>cm</span>
                </div>
              </div>
              <div class="ask-date">
                <div class="sex-title">4.体重</div>
                <div class="til-top flex">
                    <input type="text"
                           maxlength="3"
                           oninput="value=value.replace(/[^\d]/g,'')"
                           v-model.trim="weight"
                    />
                    <span>kg</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(item, index) in questionsBanner[page-1]"
            :key="item.id"
            class="ask-question"
          >
            <QuestionSelect
              :title="titleFun(index,item.last,item.title)"
              :options="
                item.content.map((key, index) => ({
                  value: labels[index],
                  title: labels[index] + '.' + key.label,
                }))
              "
              :is_multiple="item.is_multiple"
              :exclusiveOption="labels[item.exp_key]"
              :value="values[item.id] || ''"
              :onChange="
                (v) => {
                  onChange(item.id, v);
                }
              "
            />
          </div>
          <div class="assess-top-left"></div>
          <div class="assess-top-right"></div>
          <div class="assess-bottom-left"></div>
          <div class="assess-bottom-right"></div>
          <div class="page-bottom-right">
            <div @click="pageSubtract" v-if="page!=1" class="previou">上一页</div>
            <div @click="pageAdd" v-if="page!=questionsBanner.length" class="next">下一页</div>
            <div v-if="loading">
                <div v-if="page==questionsBanner.length" class="next">提交中...</div>
            </div>
            <div v-else>
                <div @click="(e)=>onSubmit(e,true)" v-if="page==questionsBanner.length" class="next">确认提交</div>
            </div>
          </div>
      </div>
      <div class="page"></div>
    </div>
  </div>
</div>
</template>
<script>
import QuestionSelect from "../components/progress/question_select";
import { getQuestions, submit, getUserInfo } from "@/services/api";
import { Toast , Notify } from "vant";
import { ref } from "vue";
export default {
  components: { QuestionSelect },
  setup() {
    const show = ref(false);
    const sex = ref();
    const showPopup = () => {
      show.value = true;
    };
    const hiddenPopup = () => {
      show.value = false;
    };
    return { sex,show, showPopup, hiddenPopup };
  },
  data() {
    const currentDate = ref("");
    const formatter = (type, val) => {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    };
    return {
      labels: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      options: [
        [
          { title: "男", value: 1 },
          { title: "女", value: 2 },
        ],
      ],
      values: {},
      questions: [],
      gender: 1,
      age: "",
      height: "",
      weight: "",
      loading: false,
      minDate: new Date(1960, 1, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate,
      time:"",
      page:1,
      questionsBanner:[],
      year:'',
      month:'',
      day:'',
      white:this.$route.query.white
    };
  },
  methods: {
    onClickLeft: function () {
      this.$router.go(-1);
    },
    isDuringDate:function(begin,beginDateStr, endDateStr){
      let curDate = new Date(begin),
          beginDate = new Date(beginDateStr),
          endDate = new Date(endDateStr);
        if (curDate >= beginDate && curDate <= endDate) {
            return true;
        }
        return false;
    },
    pageSubtract:function(){
      this.page--;
    },
    pageAdd:function(){
      this.page++;
    },
    titleFun:function(index,last,title){
      return last+5+'.'+title
    },
    dateTime(val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let hour = val.getHours();
      let minute = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    confirmPicker(val) {
      this.currentDate = this.dateTime(val);
      this.show = false;
    },
    onChange: function (id, value) {
      this.values[id] = value;
    },
    sexFun:function(sex){
      this.sex = sex;
    },
    onSetData: function (key, value) {
      if (["sex", "currentDate", "height", "weight"].indexOf(key) < -1) {
        return;
      }
      this[key] = value;
    },
    onCheckData() {
      if (this.sex && this.currentDate && this.height && this.weight) {
        if (
          this.questions.filter((item) => !this.values[item.id]).length === 0
        ) {
          return true;
        }
      }
      Toast.fail("您还有题没有答完哦");
      return false;
    },
    dateFun(date){
      if(date<10){
        return `0${date}`;
      }
      return date;
    },
    onSubmit: function (e,bool) {

      let currentDate = bool?`${this.year}-${this.dateFun(this.month)}-${this.dateFun(this.day)}`:this.currentDate;

      if (!this.onCheckData()) {
        return;
      }

      if (currentDate==0) {
        Toast.fail("出生日期不能为0哦");
        return
      }
      if (this.height==0) {
        Toast.fail("身高不能为0哦");
        return
      }
      if (this.weight==0) {
        Toast.fail("体重不能为0哦");
        return
      }
      if(bool){
        if(!this.year||!this.month||!this.day){
          Notify({ type: 'danger', message: '出生日期不能为0' });
          this.page = 1;
          return
        }
        if (!this.isDuringDate(`${this.year}/${this.month}/${this.day}`,'1920/01/01',`${new Date().getFullYear()}/${new Date().getMonth()+1}/${new Date().getDate()}`)) {
          Notify({ type: 'danger', message: '出生日期有误' });
          this.page = 1;
          return
        }
      }

      const answers = [];
      for (const item in this.values) {
        answers.push({
          id: item,
          answer: this.values[item].split(""),
        });
      }
      this.loading = true;



      submit(
        this.$route.query.user_id,
        this.$route.query.fid,
        this.$route.query.tid,
        JSON.stringify(answers),
        this.sex,
        currentDate,
        this.height,
        this.weight,
        this.$route.query.user_phone,
        this.$route.query.order_code,
        this.$route.query.user_id+""+this.time,
        bool
      )
        .then((data) => {
          if (data.code === 0) {
            this.$router.push({
              path: "/report",
              query: { id: data.data.id, user_id: this.$route.query.user_id, white:this.$route.query.white },
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.time=new Date().getTime()
    getQuestions(this.$route.query.user_id).then((data) => {
      if (data.code === 0) {
        this.questions = data.data;
        //横版
        let tions = [];
        for(let i=0,n=data.data.length;i<n;i++){
            data.data[i].last = i;
            if(i<2){
              if(tions.length==0){
                tions[0] = [data.data[i]];
              }else{
                tions[0].push(data.data[i]);
              }
            }else{
              let y = ((i-2)-(i-2)%3)/3+1;
              if(tions.length==y){
                tions[y] = [data.data[i]];
              }else{
                tions[y].push(data.data[i]);
              }
            }
        }
        this.questionsBanner = tions;

        const values = {};
        for (const item of this.questions) {
          values[item.id] = item.answer.join("");
        }
        this.values = values;
      }
    });
    getUserInfo(this.$route.query.user_id).then((data) => {
      if (data.code === 0) {
        if (data.data) {
          this.sex = data.data.sex+"";
          this.height = data.data.height;
          this.currentDate = data.data.age;
          this.weight = data.data.weight;
        }
      }
    });
  },
};
</script>
