export class Options {
  constructor(value) {
    this.selected = value;
  }

  Add(option) {
    if (!this.Has(option)) {
      this.selected += option;
    }
    return this;
  }

  Remove(option) {
    if (this.Has(option)) {
      this.selected = this.selected
        .split("")
        .filter((o) => o !== option)
        .join();
    }
    return this;
  }

  Clean() {
    this.selected = "";
    return this;
  }

  Toggle(option) {
    if (this.Has(option)) {
      this.Remove(option);
    } else {
      this.Add(option);
    }
    return this;
  }

  Has(option) {
    return this.selected.indexOf(option) > -1;
  }

  Data() {
    return this.selected;
  }
}
